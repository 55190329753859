import {
    StyledLabel,
    StyledSelectInput,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import classnames from "classnames";
import React from "react";
import { AvGroup } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";
import Switch from "react-switch";
import { numberPositiveMatch } from "@constants/index";

type ValinaConfigType = {
    port: string;
    portSelectInput: boolean;
    isConsult: boolean;
    ports: any;
    setPortSelectInput: Function;
    setPort: Function;
    setIsEdited: Function;
    speedSelectInput: boolean;
    speed: string | number;
    speeds: any;
    setSpeedSelectInput: Function;
    setSpeed: Function;
    selectInputPM: boolean;
    payment_method: any;
    paymentMethod: string;
    setPaymentMethod: Function;
    setSelectInputPM: Function;
    inputTimeout: boolean;
    setTimeOut: Function;
    setInputTimeout: Function;
    timeout: string;
    kiss: boolean;
    setKiss: Function;
    setReference: Function;
    reference: string;
};

export function ValinaConfig({
    port,
    portSelectInput,
    isConsult,
    ports,
    setPortSelectInput,
    setPort,
    setIsEdited,
    speedSelectInput,
    speed,
    speeds,
    setSpeedSelectInput,
    setSpeed,
    selectInputPM,
    payment_method,
    paymentMethod,
    setPaymentMethod,
    setSelectInputPM,
    inputTimeout,
    setTimeOut,
    setInputTimeout,
    timeout,
    kiss,
    setKiss,
    setReference,
    reference,
}: ValinaConfigType) {
    const { t } = useTranslation();

    const valinaReferencesArray: {
        label: string;
        value: string;
    }[] = ["EXEMPLE 1", "EXEMPLE 2", "EXEMPLE 3", "EXEMPLE 4"].map(
        (element: string) => {
            return {
                label: element,
                value: element,
            };
        }
    );
    return (
        <React.Fragment>
            <AvGroup>
                <StyledLabel htmlFor="text" className="mt-3">
                    {t("Reference")}
                </StyledLabel>

                <StyledSelectInput
                    className={classnames("mnt_inp_reference", {
                        readOnly__clz: isConsult,
                    })}
                    onChange={(e: any) => {
                        setReference(e.value);
                        setIsEdited(true);
                    }}
                    value={
                        reference === ""
                            ? null
                            : {
                                  label: reference,
                                  value: reference,
                              }
                    }
                    options={valinaReferencesArray}
                    name="reference"
                    placeholder={t("Select")}
                    noOptionsMessage={() => t("No options")}
                />
            </AvGroup>
            <AvGroup>
                <StyledLabel className="mt-3 required__clz" htmlFor="text">
                    {t("Port number")}
                </StyledLabel>

                <StyledSelectInput
                    className={classnames("mnt_inp_portNumber", {
                        invalid__clz: portSelectInput,
                        readOnly__clz: isConsult,
                    })}
                    value={
                        port === ""
                            ? null
                            : {
                                  label: port,
                                  value: port,
                              }
                    }
                    id="port"
                    name="port"
                    options={ports}
                    onChange={(e: any) => {
                        setPortSelectInput(false);
                        setPort(e.label);
                        setIsEdited(true);
                    }}
                    placeholder={t("Select")}
                    noOptionsMessage={() => t("No options")}
                    maxMenuHeight="80px"
                />

                {portSelectInput && (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please select a port number")}
                    </div>
                )}
            </AvGroup>
            <AvGroup>
                <StyledLabel className="mt-3 required__clz" htmlFor="text">
                    {t("Speed")}
                </StyledLabel>

                <StyledSelectInput
                    className={classnames("mnt_inp_speed", {
                        invalid__clz: speedSelectInput,
                        readOnly__clz: isConsult,
                    })}
                    value={
                        speed === ""
                            ? null
                            : {
                                  label: speed,
                                  value: speed,
                              }
                    }
                    id="speed"
                    name="speed"
                    options={speeds}
                    onChange={(e: any) => {
                        setSpeedSelectInput(false);
                        setSpeed(e.label);
                        setIsEdited(true);
                    }}
                    placeholder={t("Select")}
                    noOptionsMessage={() => t("No options")}
                    maxMenuHeight="80px"
                />
                {speedSelectInput && (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please select a speed")}
                    </div>
                )}
            </AvGroup>
            <AvGroup>
                <StyledLabel className="mt-3 required__clz" htmlFor="text">
                    {t("Method of payment")}
                </StyledLabel>
                <StyledSelectInput
                    className={classnames("mnt_drp_paymentMethod", {
                        invalid__clz: selectInputPM,
                        readOnly__clz: isConsult,
                    })}
                    value={
                        Object.keys(paymentMethod).length > 0
                            ? paymentMethod
                            : null
                    }
                    id="Method_of_payment"
                    name="Method_of_payment"
                    options={payment_method}
                    onChange={(e: any) => {
                        if (e !== null) {
                            setPaymentMethod(e);
                        }
                        setIsEdited(true);
                        if (e !== null && Object.keys(e).length > 0) {
                            setSelectInputPM(false);
                        } else {
                            setSelectInputPM(true);
                        }
                    }}
                    placeholder={t("Select")}
                    noOptionsMessage={() => t("No options")}
                    maxMenuHeight="80px"
                />
                {selectInputPM && (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please select a method of payment")}
                    </div>
                )}
            </AvGroup>
            <AvGroup>
                <StyledLabel className="mt-3 required__clz" htmlFor="text">
                    {t("Timeout")}
                    {` (s)`}
                </StyledLabel>
                <StyledTextInput
                    className={classnames("mnt_inp_break", {
                        input__clz: inputTimeout,
                        readOnly__clz: isConsult,
                    })}
                    autocomplete="off"
                    id="ipAddress"
                    name="ipAddress"
                    placeholder={t("Write")}
                    type="text"
                    min={0}
                    onChange={(e: any) => {
                        let val = e.target.value;
                        setTimeOut(val);
                        if (
                            !numberPositiveMatch.test(val) ||
                            val.trim() === ""
                        ) {
                            setInputTimeout(true);
                        } else {
                            setInputTimeout(false);
                        }
                        setIsEdited(true);
                    }}
                    value={timeout}
                    validate={{
                        pattern: {
                            value: /.*$/,
                            errorMessage: t("Time out is invalid"),
                        },
                    }}
                />
                {inputTimeout && timeout.trim() === "" ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please enter the timeout")!}
                    </div>
                ) : inputTimeout ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Timeout is invalid")!}
                    </div>
                ) : null}
            </AvGroup>
            <AvGroup>
                <StyledLabel className="mt-3" htmlFor="text">
                    {t("Activate the protocol KISS")}
                </StyledLabel>
                <div className="float-right">
                    <Switch
                        offColor="#faafb4"
                        offHandleColor="#E30613"
                        onColor="#c2eec4"
                        className={classnames("mnt_icn_kiss mt-3  pl-2", {
                            readOnly__clz: isConsult,
                        })}
                        onHandleColor="#34C38F"
                        uncheckedIcon={false}
                        checkedIcon={false}
                        checked={kiss}
                        height={20}
                        handleDiameter={24}
                        name="activate-protocol-kiss"
                        onChange={(e: any) => {
                            setKiss(!kiss);
                            setIsEdited(true);
                        }}
                    />
                </div>
            </AvGroup>
        </React.Fragment>
    );
}
