import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import { generalConfigStore } from "@store";

import { CompositeProductStepPreview } from "./CompositeProductStepPreview";
import { GenericInterfacePreview } from "./GenericInterfacePreview";
import { SummaryPreview } from "./SummaryPreview";
import CustomerBenefitPreview from "./CustomerBenefitPreview";

export function OrderTakingPreview(): JSX.Element {
    const { t } = useTranslation();
    const { selectSubStepOrderTaking } = useSnapshot(generalConfigStore);

    return (
        <div
            className="d-flex justify-content-center align-items-center p-1 m-2"
            style={{
                backgroundColor: "#363636",
                borderRadius: "10px",
                height: "740px",
                width: "448px",
            }}
        >
            <div
                style={{
                    backgroundColor: "#FFFFFF",
                    height: "725px",
                    width: "430px",
                    borderRadius: "10px",
                }}
            >
                <div
                    style={{
                        backgroundColor: "#FFFFFF",
                        height: "725px",
                        width: "430px",
                        borderRadius: "10px",
                    }}
                >
                    {selectSubStepOrderTaking === t("Generic interface") ? (
                        <GenericInterfacePreview />
                    ) : selectSubStepOrderTaking ===
                      t("Composite product step") ? (
                        <CompositeProductStepPreview />
                    ) : selectSubStepOrderTaking === t("Summary") ? (
                        <SummaryPreview />
                    ) : selectSubStepOrderTaking === t("Customer benefit") ? (
                        <CustomerBenefitPreview />
                    ) : null}
                </div>
            </div>
        </div>
    );
}
