import { proxy, subscribe } from "valtio";
import { HAS_NO_LOYALTY } from "../../constants";
type resourceIpType = {
    id: string;
    shopId: string;
    type: string;
    ip: string;
};
type StoreType = {
    collapsed: boolean;
    oneShop: boolean;
    isMobile: boolean;
    isMobileBurgerButtonClicked: boolean;
    userID: number;
    shopID: string;
    franchiseID: string;
    isLoadingAssociateValidation: boolean;
    test: number;
    projectId: string;
    isModalConfirmationOpened: boolean;
    numberActive: number;
    numberArchived: number;
    nbrShopsFranchise: number;
    shopPays: string;
    shopName: string;
    name: string;
    operatorID: string;
    resourceIp: Array<resourceIpType>;
    isShopUser: boolean;
    designationProjectTemplate: string;
    noteProjectTemplate: string;
    pathsAuthorization: any;
    mainColor: string;
    secondaryColor: string;
    isModalOpened: boolean;
    numberClick: number;
    isDefault: boolean;
    limitToast: number;
    fideltyType: number;
    posEditor: string;
};
let storageObject = window.localStorage.getItem("store");

const storedStateStore = JSON.parse(
    storageObject !== null ? storageObject : "{}"
);
let OID: any =
    window.localStorage.getItem("Uid_Entitie_User") !== undefined
        ? window.localStorage.getItem("Uid_Entitie_User")
        : "";

const defaultStateStore = {
    collapsed: false,
    isMobileBurgerButtonClicked: false,
    isMobile: false,
    isModalConfirmationOpened: false,
    oneShop: false,
    userID: 106,
    shopID: 6,
    franchiseID: OID,
    isLoadingAssociateValidation: false,
    test: 10,
    projectId: "",
    nbrShopsFranchise: 0,
    shopPays: "",
    shopName: "",
    name: "",
    operatorID: OID,
    resourceIp: [],
    isShopUser: false,
    designationProjectTemplate: "",
    noteProjectTemplate: "",
    pathsAuthorization: [],
    mainColor: "#171616",
    secondaryColor: "",
    isModalOpened: false,
    numberClick: 0,
    isDefault: false,
    limitToast: 1,
    fideltyType: HAS_NO_LOYALTY,
    posEditor: "",
};

const initialStateStore =
    Object.keys(storedStateStore).length ===
    Object.keys(defaultStateStore).length
        ? storedStateStore
        : defaultStateStore;

export const store = proxy<StoreType>(initialStateStore);
subscribe(store, () => {
    window.localStorage.setItem("store", JSON.stringify(store));
});

export function setLimitToast(val: number): void {
    store.limitToast = val;
}
export function setCollapsed(collapsed: boolean): void {
    store.collapsed = collapsed;
}
export function setIsDefault(isDefault: boolean): void {
    store.isDefault = isDefault;
}
export function setIsModalOpened(isModalOpened: boolean): void {
    store.isModalOpened = isModalOpened;
}
export function setNumberClick(numberClick: number): void {
    store.numberClick = numberClick;
}
export function setIsShopUser(isShopUser: boolean): void {
    store.isShopUser = isShopUser;
}
export function setPathsAuthorization(data: any): void {
    store.pathsAuthorization = data;
}
export function setIsMobile(isMobile: boolean): void {
    store.isMobile = isMobile;
}
export function setResourceIp(x: Array<resourceIpType>) {
    store.resourceIp = x;
}
export function setNbrShopsFranchise(x: number) {
    store.nbrShopsFranchise = x;
}
export function setIsLoadingAssociateValidation(
    isLoadingAssociateValidation: boolean
): void {
    store.isLoadingAssociateValidation = isLoadingAssociateValidation;
}
export function setOneShop(oneShop: boolean): void {
    store.oneShop = oneShop;
    if (oneShop) {
        store.userID = 106;
        store.shopID = "casa_italiaboutique1";
        store.franchiseID = "8ecfe5ee-926b-423e-8050-c911dd81c150";
    } else {
        store.userID = 106;
        store.shopID = "6";
        store.franchiseID = "8ecfe5ee-926b-423e-8050-c911dd81c150";
    }
}
export function setOneShopSwitch(
    franchise: string,
    shop: string,
    shopPays: string,
    shopName: string
) {
    store.franchiseID = franchise;
    store.shopID = shop;
    store.oneShop = true;
    store.userID = 106;
    store.shopPays = shopPays;
    store.shopName = shopName;
    store.operatorID = "";
    let OID: any =
        window.localStorage.getItem("Uid_Entitie_User") !== undefined
            ? window.localStorage.getItem("Uid_Entitie_User")
            : "";
    store.operatorID = OID;
}
export function setMultiShopSwitch(franchise: string, shopName: string) {
    store.franchiseID = franchise;
    store.shopName = shopName;
    store.shopID = "0";
    store.oneShop = false;
    store.userID = 106;
    store.shopPays = "";
    let OID: any =
        window.localStorage.getItem("Uid_Entitie_User") !== undefined
            ? window.localStorage.getItem("Uid_Entitie_User")
            : "";
    store.operatorID = OID;
}

export function setShopID(shopID: number): void {
    store.shopID = "" + shopID + "";
}
export function setProjectId(projectId: string): void {
    store.projectId = projectId;
}
export function setUserID(userID: number): void {
    store.userID = userID;
}

export function setfranchiseID(franchiseID: string): void {
    store.franchiseID = franchiseID;
}

export function setIsMobileBurgerButtonClicked(
    isMobileBurgerButtonClicked: boolean
): void {
    store.isMobileBurgerButtonClicked = isMobileBurgerButtonClicked;
}
export function setIsModalConfirmationOpened(
    isModalConfirmationOpened: boolean
): void {
    store.isModalConfirmationOpened = isModalConfirmationOpened;
}

export function setInitialDefaultStateStore() {
    defaultStateStore.collapsed = false;
    defaultStateStore.isMobileBurgerButtonClicked = false;
    defaultStateStore.isModalConfirmationOpened = false;
    defaultStateStore.oneShop = false;
    defaultStateStore.userID = 106;
    defaultStateStore.shopID = 6;
    defaultStateStore.franchiseID = "8ecfe5ee-926b-423e-8050-c911dd81c150";
    defaultStateStore.isLoadingAssociateValidation = false;
    defaultStateStore.test = 10;
    defaultStateStore.projectId = "";
    defaultStateStore.nbrShopsFranchise = 0;
    defaultStateStore.shopPays = "";
    let OID: any =
        window.localStorage.getItem("Uid_Entitie_User") !== undefined
            ? window.localStorage.getItem("Uid_Entitie_User")
            : "";
    store.operatorID = OID;
}

export function setName(name: string): void {
    store.name = name;
}
export function setDesignationProjectTemplate(
    designationProjectTemplate: string
): void {
    store.designationProjectTemplate = designationProjectTemplate;
}
export function setNoteProjectTemplate(noteProjectTemplate: string): void {
    store.noteProjectTemplate = noteProjectTemplate;
}

export function setMainColor(color: any) {
    store.mainColor = color;
}

export function setSecondaryColor(color: any) {
    store.secondaryColor = color;
}

export function setFidelity(fideltyType: number) {
    store.fideltyType = fideltyType;
}

export function setPosEditor(posEditor: string) {
    store.posEditor = posEditor;
}
