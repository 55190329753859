import React from "react";
import { designStore, generalConfigStore, setProject } from "@store";
import { useSnapshot } from "valtio";
import { t } from "i18next";
import ReactSwitch from "react-switch";

export function SwitchItemsComponent(): JSX.Element {
    const { project } = useSnapshot(generalConfigStore);
    const { registerForm } = useSnapshot(designStore);

    const isActiveSwitch = (elmentName: string) => {
        return ((project.template.pages.subSteps["accountFidelite"] as PageType)
            .registerForm as registerFormType)[elmentName].active;
    };

    function handleSwitchForm(checked: any, _: any, id: string) {
        setProject({
            ...project,
            template: {
                ...project.template,
                pages: {
                    ...project.template.pages,
                    subSteps: {
                        ...project.template.pages.subSteps,
                        accountFidelite: {
                            ...(project.template.pages.subSteps[
                                "accountFidelite"
                            ] as PageType),
                            registerForm: {
                                ...(project.template.pages.subSteps[
                                    "accountFidelite"
                                ] as PageType).registerForm,
                                [id]: {
                                    ...((project.template.pages.subSteps[
                                        "accountFidelite"
                                    ] as PageType)
                                        .registerForm as registerFormType)[id],
                                    active: checked,
                                },
                            },
                        },
                    },
                },
            },
        });
    }

    const registerFormNewOrder = Object.fromEntries(
        Object.entries(registerForm)
            .sort((a, b) => a[1].group - b[1].group)
            .filter(([_, element]) => element.group !== 4)
    );
    return (
        <React.Fragment>
            {Object.keys(JSON.parse(JSON.stringify(registerFormNewOrder))).map(
                (element: any, i: any) => {
                    return (
                        <div
                            key={i}
                            className="d-flex justify-content-between  align-items-center mb-3"
                        >
                            <label>{t(element)}</label>
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                id={element}
                                checked={isActiveSwitch(element)}
                                onChange={handleSwitchForm}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={50}
                                height={20}
                                disabled={
                                    element === "phoneNumber" ||
                                    element === "firstName"
                                        ? true
                                        : false
                                }
                            />
                        </div>
                    );
                }
            )}
        </React.Fragment>
    );
}
