import React from "react";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { CheckboxIcon } from "@aureskonnect/react-ui";
import { toast } from "react-toastify";
import {
    EyeIcon,
    RefreshIcon,
    StyledButton,
    StyledIconButton,
    CrossIcon,
} from "@aureskonnect/react-ui";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { v4 as uuid } from "uuid";

import {
    computedStore,
    generalConfigStore,
    setActiveLanguages,
    setChosenTemplate,
    setConfigOptionsCustomerAccount,
    setDefaultLanguage,
    setDefaultWays,
    setFiles,
    setGeneralChosenTransition,
    setGeneralTransition,
    setImageKey,
    setInformationMessageItem,
    setInitItemsFromGeneralSetting,
    setIsConfigured,
    setIsCustomerAccountActive,
    setIsInformationModesEmpty,
    setIsSpecificTransitionChosen,
    setIsStateChanged,
    setItems,
    setSelectedLanguage,
    setSelectedSaleModeValue,
    setVisualizedTemplate,
    store,
} from "@store";
import { store as VerticalLayoutStore } from "../../../components/VerticalLayout/store";
import { store as mainColorStore } from "@store";
import { FlexboxGrid } from "@components/Common/FlexboxGrid";
import { SwiperWrapper } from "../../../components/FullscreenPreview/SwiperWrapper";
import { Modal as Modala } from "../../../components/Common/ModalWrapper/Modal";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { moveArrayElementToTheBegin } from "@helpers/general";
import {
    ACCOUNT_CREATION_ID,
    ACCOUNT_CREATION_ITEM,
    GODIA_LOYALTY,
} from "@constants/index";

type PropsType = {
    template: NewTemplateType;
};

export function Template({ template }: PropsType): JSX.Element {
    const { t } = useTranslation();

    const { chosenTemplate, project, isConfigured } = useSnapshot(
        generalConfigStore
    );
    const { items } = useSnapshot(computedStore);
    const { images } = useSnapshot(store);
    const { mainColor } = useSnapshot(mainColorStore);
    const { franchiseID, shopID, oneShop, fideltyType } = useSnapshot(
        VerticalLayoutStore
    );
    let shopId = oneShop ? shopID : "0";
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isAlertModalOpened, setIsAlertModalOpened] = React.useState<boolean>(
        false
    );
    const [isModalShown, setModalVisibility] = React.useState(false);

    function alertToggle() {
        setIsAlertModalOpened(!isAlertModalOpened);
    }
    function toggle() {
        setIsModalOpened(!isModalOpened);
    }

    async function handleChosenTemplateOnclickEvent() {
        const getLanguagesUrl: Response = await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/consomation/langue?franchiseId=${franchiseID}&shopId=${shopId}`,
            {
                method: "GET",
            }
        );
        const languages = await getLanguagesUrl.json();

        const getSalesMethodUrl: Response = await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/consomation/sale?franchiseId=${franchiseID}&shopId=${shopId}`,
            {
                method: "GET",
            }
        );
        const saleMethods = await getSalesMethodUrl.json();

        if (
            saleMethods.filter(
                (item: any) =>
                    item.active === true &&
                    item.id !== "f25e7c96-b5d3-4f2e-acd0-1500258283c2"
            ).length === 0 ||
            languages.filter((item: any) => item.active === true).length === 0
        ) {
            toast.warning(
                `${t(
                    `Please note that the assignment could not be made to one or more stores. Please check the store(s) data settings.`
                )!}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
        } else {
            let templateFiles = images.filter(
                (image: ImageType) => image.id === template.id
            )[0].files;

            const getReglementUrl: Response = await fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/consomation/reglement?franchiseId=${franchiseID}&shopId=${shopId}`,
                {
                    method: "GET",
                }
            );
            const reglements = await getReglementUrl.json();

            const getInformationMethodUrl: Response = await fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/consomation/information?franchiseId=${franchiseID}&shopId=${shopId}`,
                {
                    method: "GET",
                }
            );

            const getLogoUrl: Response = await fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/consomation/logo?franchiseId=${franchiseID}&shopId=${shopId}`,
                {
                    method: "GET",
                }
            );

            const informationModes = await getInformationMethodUrl.json();

            const logo = await getLogoUrl.json();

            if (
                informationModes.length === 0 ||
                informationModes.filter((item: ProjectMainContentItemType) => {
                    return saleMethods.filter((saleMethod: any) => {
                        return (
                            Object.keys(item.active).includes(
                                saleMethod.name
                            ) && saleMethod.active === true
                        );
                    });
                }).length === 0
            ) {
                setIsInformationModesEmpty(true);
            }

            if (informationModes.length > 0) {
                setIsInformationModesEmpty(false);
            }

            const indexOfCustomerAccountOption = (template.content
                .options as OptionsType).items.findIndex(
                (item: OptionsItemType) =>
                    item.id === "00fa26a0-0bc0-4986-9769-4ffe08d4498d"
            );
            let coptyItems: OptionsItemType[] = [
                ...(template.content.options as OptionsType).items,
            ];
            if (
                (template.content.options as OptionsType).items[
                    indexOfCustomerAccountOption
                ].title === "Loyalty account"
            ) {
                coptyItems[indexOfCustomerAccountOption] = {
                    ...coptyItems[indexOfCustomerAccountOption],

                    title: "Customer account",
                    languages: {
                        en: {
                            ...(template.content.options as OptionsType).items[
                                indexOfCustomerAccountOption
                            ].languages.en,
                            name: "Customer account",
                        },
                        es: {
                            ...(template.content.options as OptionsType).items[
                                indexOfCustomerAccountOption
                            ].languages.es,
                            name: "Cuenta de cliente",
                        },
                        fr: {
                            ...(template.content.options as OptionsType).items[
                                indexOfCustomerAccountOption
                            ].languages.fr,
                            name: "Compte client",
                        },
                        it: {
                            ...(template.content.options as OptionsType).items[
                                indexOfCustomerAccountOption
                            ].languages.it,
                            name: "Conto cliente",
                        },
                        nl: {
                            ...(template.content.options as OptionsType).items[
                                indexOfCustomerAccountOption
                            ].languages.nl,
                            name: "Kundenkonto",
                        },
                        pt: {
                            ...(template.content.options as OptionsType).items[
                                indexOfCustomerAccountOption
                            ].languages.pt,
                            name: "Conta de cliente",
                        },
                    },
                };
            }

            const customerAccountObject = (template.pages.elements
                .orderTaking as OrderTakingPageType).design.items.findIndex(
                (item: any) =>
                    item.id === "8f561d9c-dac8-4037-89a6-0c2adb8a40bb"
            );
            const copyDesignItems = [
                ...(template.pages.elements.orderTaking as OrderTakingPageType)
                    .design.items,
            ];
            if (
                (template.pages.elements.orderTaking as OrderTakingPageType)
                    .design.items[customerAccountObject].name ===
                "Loyalty account"
            ) {
                copyDesignItems[customerAccountObject] = {
                    ...copyDesignItems[customerAccountObject],
                    name: "Customer account",
                };
            }
            let authenticationModesOptions = (template.content
                .authenticationModes as ProjectContentItemType).items;
            if (fideltyType === GODIA_LOYALTY) {
                if (
                    (template.content
                        .connection as ProjectContentItemType).items.find(
                        (item: any) => item.id === ACCOUNT_CREATION_ID
                    ) === undefined
                ) {
                    (template.content
                        .connection as ProjectContentItemType).items.unshift(
                        ACCOUNT_CREATION_ITEM
                    );
                }
            } else {
                (template.content
                    .connection as ProjectContentItemType).items.filter(
                    (item: any) => item.id !== ACCOUNT_CREATION_ID
                );
            }

            template = {
                ...template,
                pages: {
                    ...template.pages,
                    elements: {
                        ...template.pages.elements,
                        orderTaking: {
                            ...(template.pages.elements
                                .orderTaking as OrderTakingPageType),
                            design: {
                                ...(template.pages.elements
                                    .orderTaking as OrderTakingPageType).design,
                                items: copyDesignItems as ActionsButtonsDesignItemType[],
                            },
                        },
                    },
                },
                content: {
                    ...template.content,
                    authenticationModes: {
                        ...project.template.content.authenticationModes,
                        items: authenticationModesOptions,
                    },
                    options: {
                        ...(template.content.options as OptionsType),
                        items: coptyItems,
                    },
                    languages: {
                        ...template.content.languages,
                        items:
                            languages.filter(
                                (language: LanguagesItemType) =>
                                    language.active === true &&
                                    language.isDefault === false
                            ).length > 1
                                ? moveArrayElementToTheBegin(
                                      languages.find(
                                          (language: LanguagesItemType) =>
                                              language.isDefault === true
                                      ),
                                      moveArrayElementToTheBegin(
                                          languages.find(
                                              (language: LanguagesItemType) =>
                                                  language.active === true &&
                                                  language.isDefault === false
                                          ),
                                          languages
                                      )
                                  )
                                : languages,
                    },
                    salesMethods: {
                        items: saleMethods,
                    },
                    meansOfPayment: {
                        items: reglements,
                    },
                    informationModes: {
                        items: informationModes,
                    },
                    generalDesign: {
                        ...template.content.generalDesign,
                        logo,
                    },
                },
            };
            setInitItemsFromGeneralSetting({
                informationModes,
                saleMethods,
                reglements,
                languages,
                logo,
            });
            saleMethods.map((item: ProjectMainContentItemType) => {
                const data = {
                    id: item.id,
                    name: item.name,
                    content: Object.fromEntries(
                        Object.entries(item.languages).map(([key, value]) => [
                            key,
                            {
                                path: value.content as string,
                                defaultImage: value.content,
                            },
                        ])
                    ),
                };
                return (templateFiles = [...templateFiles, data]);
            });
            informationModes.map((item: ProjectMainContentItemType) => {
                const data = {
                    id: item.id,
                    name: item.name,
                    content: Object.fromEntries(
                        Object.entries(item.languages).map(([key, value]) => [
                            key,
                            {
                                path: value.content as string,
                                defaultImage: value.content,
                            },
                        ])
                    ),
                };
                return (templateFiles = [...templateFiles, data]);
            });
            languages.map((item: LanguagesItemType) => {
                const data = {
                    id: item.id,
                    name: item.name,
                    content: item.flag,
                    defaultImage: item.flag,
                };

                for (let file of templateFiles) {
                    if (file.content !== undefined) {
                        if (typeof file.content === "object") {
                            // eslint-disable-next-line no-prototype-builtins
                            if (
                                file.content.hasOwnProperty(item.name) === false
                            ) {
                                const index = templateFiles.indexOf(file);
                                if (index > -1) {
                                    templateFiles[index] = {
                                        ...templateFiles[index],
                                        content: {
                                            ...file.content,
                                            [item.name]: file.content.fr,
                                        },
                                    };
                                }
                            }
                        }
                    }
                }

                return (templateFiles = [...templateFiles, data]);
            });
            reglements
                .filter(
                    (item: ProjectMainContentItemType) =>
                        item.name !== "At the counter" && item.name !== "BDP"
                )
                .map((item: ProjectMainContentItemType) => {
                    const data = {
                        id: item.id,
                        name: item.name,
                        content: Object.fromEntries(
                            Object.entries(item.languages).map(
                                ([key, value]) => [
                                    key,
                                    {
                                        path: value.content as string,
                                        defaultImage: value.content,
                                    },
                                ]
                            )
                        ),
                    };
                    return (templateFiles = [...templateFiles, data]);
                });
            const dataLogo = {
                id: logo.id,
                key: "Logo",
                name: logo.name,
                content: logo.content,
                defaultImage: logo.content,
            };

            templateFiles = [...templateFiles, dataLogo];

            setChosenTemplate(template, templateFiles);
            setFiles(templateFiles);
            setDefaultLanguage(
                (template.content.languages as LanguagesType).items.filter(
                    (language) => language.isDefault === true
                )[0]
            );
            setSelectedLanguage(
                (template.content.languages as LanguagesType).items.filter(
                    (language) => language.isDefault === true
                )[0].name
            );
            setActiveLanguages(
                moveArrayElementToTheBegin(
                    (template.content.languages as LanguagesType).items.filter(
                        (element: LanguagesItemType): boolean =>
                            element.isDefault === true
                    )[0],
                    (template.content.languages as LanguagesType).items.filter(
                        (language) => language.active === true
                    )
                )
            );
            const selectedSaleMethodDeliveryExist = (template.content
                .salesMethods as ProjectContentItemType).items.filter(
                (sale: any) => sale.name === "Delivery"
            )[0];
            if (
                selectedSaleMethodDeliveryExist !== undefined &&
                selectedSaleMethodDeliveryExist.active === true
            ) {
                setIsCustomerAccountActive(true);

                setIsStateChanged(true);
                setConfigOptionsCustomerAccount(true);
            } else {
                setIsCustomerAccountActive(
                    (template.content.options as OptionsType).items.filter(
                        (item) => item.title === "Customer account"
                    )[0].active
                );
            }
            const filterInformationModeInSaleMethod = (template.content
                .salesMethods as ProjectContentItemType).items.filter(
                (saleMethod) => {
                    return (
                        saleMethod.name !== "Retrait C&C" &&
                        (saleMethod.informationModes as InformationModesContentType)
                            .items.length !== 0 &&
                        saleMethod.active === true
                    );
                }
            );
            if (
                (template.content.informationModes as ProjectContentItemType)
                    .items.length === 0 ||
                filterInformationModeInSaleMethod.length === 0
            ) {
                setSelectedSaleModeValue(
                    (template.content
                        .salesMethods as ProjectContentItemType).items.filter(
                        (saleMethod) => {
                            return (
                                saleMethod.name !== "Retrait C&C" &&
                                saleMethod.active === true
                            );
                        }
                    )[0].name
                );
            } else {
                setSelectedSaleModeValue(
                    (template.content
                        .salesMethods as ProjectContentItemType).items.filter(
                        (saleMethod) => {
                            return (
                                saleMethod.name !== "Retrait C&C" &&
                                (saleMethod.informationModes as InformationModesContentType)
                                    .items.length !== 0 &&
                                saleMethod.active === true
                            );
                        }
                    )[0].name
                );
            }

            setGeneralTransition({
                prettyName: "None",
                timeout: 0,
                className: "none",
            });
            setGeneralChosenTransition("none");
            setIsSpecificTransitionChosen(false);

            if (Object.keys(template.pages.ways).length === 0) {
                const localWays: (
                    | PageType
                    | ConsommationPageType
                    | StandbyScreenPageType
                    | PaymentPageType
                    | OrderTakingPageType
                    | PrinterOptionsPageType
                )[] = Object.values(template.pages.elements)
                    .filter(
                        (itemElement: any) =>
                            itemElement.name === "standbyScreen" ||
                            itemElement.name === "salesMethods" ||
                            itemElement.name === "orderTaking" ||
                            itemElement.name === "finalMessage" ||
                            itemElement.name === "payment" ||
                            itemElement.name === "meansOfPayment" ||
                            itemElement.name === "printerOptions"
                    )
                    .map(
                        (
                            item:
                                | PageType
                                | ConsommationPageType
                                | StandbyScreenPageType
                                | PaymentPageType
                                | OrderTakingPageType
                                | PrinterOptionsPageType
                        ) => ({
                            ...item,
                            prefix: "ways",
                            animation: "none",
                        })
                    );

                const localElements: (
                    | PageType
                    | ConsommationPageType
                    | StandbyScreenPageType
                    | PaymentPageType
                    | OrderTakingPageType
                    | PrinterOptionsPageType
                )[] = Object.values(template.pages.elements)
                    .filter(
                        (itemElement) =>
                            itemElement.name === "connection" ||
                            itemElement.name === "informationModes" ||
                            itemElement.name === "authenticationModes" ||
                            itemElement.name === "loyaltyConsommation" ||
                            itemElement.name === "informationModesConsommation"
                    )
                    .map(
                        (
                            item:
                                | PageType
                                | ConsommationPageType
                                | StandbyScreenPageType
                                | PaymentPageType
                                | OrderTakingPageType
                                | PrinterOptionsPageType
                        ) => ({
                            ...item,
                            prefix: "elements",
                        })
                    );

                const newLocalProjectWays: ItemsType = {};

                localWays.forEach((item, index) => {
                    newLocalProjectWays[item.name] = {
                        ...item,
                        index: index,
                    };
                });

                const newLocalProjectElements: ItemsType = {};

                localElements.forEach((item, index) => {
                    newLocalProjectElements[item.name] = {
                        ...item,
                        index: index,
                    };
                });
                setItems({
                    ...items,
                    ways: newLocalProjectWays,
                    elements: newLocalProjectElements,
                    subSteps: template.pages.subSteps,
                });
                setDefaultWays({
                    ...items,
                    ways: newLocalProjectWays,
                    elements: newLocalProjectElements,
                    subSteps: template.pages.subSteps,
                });
            } else {
                setItems(template.pages);
                setDefaultWays(template.pages);
            }

            setIsConfigured(true);
            let paymentInformationItem = (template.content
                .payment as PaymentContentItemType).items.filter(
                (regulationMode) => {
                    return regulationMode.shortName === "creditCard";
                }
            )[0];
            for (let language of Object.keys(
                (paymentInformationItem as PaymentMainContentItemType).languages
            )) {
                setInformationMessageItem({
                    ...paymentInformationItem.languages[language].amount
                        .informationMessage,
                    name: paymentInformationItem.languages[
                        language
                    ].amount.informationMessage.name.replace("£", " €"),
                    defaultName: paymentInformationItem.languages[
                        language
                    ].amount.informationMessage.defaultName.replace("£", " €"),
                });
            }
        }
    }

    function handleResetTemplateOnclickEvent() {
        handleChosenTemplateOnclickEvent();
        setImageKey(uuid());
        setIsModalOpened(false);
    }

    const closeModal = () => {
        setModalVisibility(false);
    };

    return (
        <React.Fragment>
            <React.Fragment>
                <div>
                    <FlexboxGrid className="flex-column">
                        <FlexboxGrid
                            alignItemsCentered={true}
                            styles={{ height: "40px", fontSize: "15px" }}
                        >
                            {template.name}
                        </FlexboxGrid>
                        <FlexboxGrid
                            styles={{
                                width: "270px",
                                height: "470px",
                                border: "1px solid #BCBCBC",
                                borderRadius: "1rem",
                                gap: "5px",
                                borderBottom:
                                    chosenTemplate.name === template.name
                                        ? "10px solid #34C38F"
                                        : "1px solid #BCBCBC",
                            }}
                            className="flex-column"
                        >
                            <img
                                src={`./images/${template.coverImage}`}
                                alt={template.name}
                                style={{
                                    height: "400px",
                                    width: "268px",
                                    objectFit: "fill",
                                    borderTopRightRadius: "1rem",
                                    borderTopLeftRadius: "1rem",
                                }}
                            />
                            <FlexboxGrid
                                gap="5px"
                                alignItemsCentered={true}
                                justifyContentCentered={true}
                            >
                                <StyledButton
                                    rounded={true}
                                    className="pme_btn_buttonSelectTemplate"
                                    variant={
                                        chosenTemplate.name === template.name
                                            ? "success"
                                            : mainColor
                                    }
                                    onClick={() => {
                                        if (isConfigured === false) {
                                            handleChosenTemplateOnclickEvent();
                                        }
                                    }}
                                >
                                    {chosenTemplate.name === template.name
                                        ? t("Selected")
                                        : t("Choose")}
                                </StyledButton>
                                {chosenTemplate.name === template.name ? (
                                    <StyledIconButton
                                        disabled={
                                            project.Status === "Publié"
                                                ? true
                                                : false
                                        }
                                        title={t("Reset")}
                                        outline={true}
                                        variant="danger"
                                        className="m-0 pme_btn_buttonReinitializeTemplate"
                                        onClick={() => {
                                            if (project.Status !== "Publié") {
                                                setIsModalOpened(
                                                    !isModalOpened
                                                );
                                            }
                                        }}
                                    >
                                        <RefreshIcon height={20} width={20} />
                                    </StyledIconButton>
                                ) : null}
                                <StyledIconButton
                                    rounded={true}
                                    className="m-0 bg-dark pme_btn_buttonVisualizeTemplate"
                                    onClick={() => {
                                        setModalVisibility(true);
                                        setVisualizedTemplate(template);
                                    }}
                                >
                                    <EyeIcon
                                        height={20}
                                        width={20}
                                        fill="white"
                                    />
                                </StyledIconButton>
                            </FlexboxGrid>
                        </FlexboxGrid>
                    </FlexboxGrid>
                    <Modal
                        isOpen={isModalOpened}
                        toggle={toggle}
                        backdrop={false}
                        fade={false}
                        centered
                    >
                        <ModalHeader toggle={toggle}>{t("Alert")}</ModalHeader>
                        <ModalBody>
                            <div>
                                {t(
                                    "Resetting the template will cause you to lose all changes made"
                                )}
                            </div>
                            <FlexboxGrid
                                justifyContentCentered={true}
                                className="pt-4"
                                gap="10px"
                            >
                                <CustomMainColorButton
                                    rounded={true}
                                    variant="primary"
                                    outline
                                    onClick={() => setIsModalOpened(false)}
                                >
                                    {t("Cancel")}
                                </CustomMainColorButton>
                                <StyledButton
                                    rounded={true}
                                    variant="danger"
                                    onClick={handleResetTemplateOnclickEvent}
                                >
                                    {t("Reset")}
                                </StyledButton>
                            </FlexboxGrid>
                        </ModalBody>
                    </Modal>
                </div>
                <Modal
                    isOpen={isAlertModalOpened}
                    toggle={alertToggle}
                    backdrop={false}
                    fade={false}
                    centered
                >
                    <ModalHeader toggle={alertToggle}>
                        <span
                            style={{
                                textAlign: "left",
                                font: " normal normal 600 25px Segoe UI",
                                color: "#000000",
                            }}
                        >
                            {" "}
                            {t("Alert")}
                        </span>
                    </ModalHeader>
                    <ModalBody
                        className="d-flex flex-column"
                        style={{ gap: "10px" }}
                    >
                        <div
                            style={{
                                textAlign: "center",
                                font: " normal normal 600 16px/10px Segoe UI",
                                color: "#2B2828",
                            }}
                        >
                            {t("Warning! Missing setting.")}
                        </div>
                        <div
                            style={{
                                font: " normal normal 600 16px/20px Segoe UI",
                                textAlign: "left",
                                color: "#2B2828",
                                padding: "14px",
                            }}
                        >
                            {t(
                                "To configure your KIOSK, it is essential to configure the elements in the general configuration section."
                            )}
                        </div>
                        <FlexboxGrid
                            className="pt-4 justify-content-end"
                            gap="10px"
                        >
                            <CustomMainColorButton
                                rounded
                                variant="primary"
                                onClick={() => setIsAlertModalOpened(false)}
                            >
                                {t("Close")}
                            </CustomMainColorButton>
                        </FlexboxGrid>
                    </ModalBody>
                </Modal>
            </React.Fragment>
            {isModalShown && (
                <Modala close={closeModal}>
                    <div
                        style={{
                            height: "940px",
                            display: "grid",
                            gridTemplateRows: "0.001fr 0.999fr",
                            marginTop: "-185px",
                            marginLeft: "-280px",
                            borderRadius: "9px",
                            background:
                                "linear-gradient(227.9deg, #1F1726 0%, #120E16 100%)",
                            boxShadow:
                                "-5px -5px 11px #221A2B, 5px 5px 11px #16101C",
                            position: "fixed",
                            zIndex: 500000,
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "start",
                                justifyContent: "end",
                                padding: "10px",
                            }}
                        >
                            <CrossIcon
                                fill="white"
                                height={10}
                                width={10}
                                onClick={() => {
                                    closeModal();
                                }}
                            />
                        </div>

                        <SwiperWrapper />
                    </div>
                </Modala>
            )}
        </React.Fragment>
    );
}
