import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Nav, NavItem, TabContent, TabPane, NavLink } from "reactstrap";
import { setSelectSubStepOrderTaking } from "@store";

import { CompositeProductStep } from "./CompositeProductStep";
import { GenericInterface } from "./GenericInterface";
import { Summary } from "./Summary";
import { CustomerBenefit } from "./CustomerBenefit";

import "@pages/GeneralConfig/Design/index.css";
import "./index.css";
import { GODIA_LOYALTY } from "@constants/index";
import { useSnapshot } from "valtio";
import { store } from "@components/VerticalLayout/store";

export function OrderTakingContent(): JSX.Element {
    const { t } = useTranslation();
const {fideltyType} = useSnapshot(store);
    const [activeTab, setActiveTab] = React.useState<number>(0);

    const tabs: TabsType[] = [
        {
            title: t("Generic interface"),
            content: <GenericInterface />,
        },
        {
            title: t("Composite product step"),
            content: <CompositeProductStep />,
        },
        {
            title: t("Summary"),
            content: <Summary />,
        },
    ];

    if (fideltyType === GODIA_LOYALTY) {
        tabs.push({
            title: t("Customer benefit"),
            content: <CustomerBenefit />,
        });
    }

    function toggleNav(tab: number) {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }

    React.useEffect(() => {
        setSelectSubStepOrderTaking("Interface générique");
    }, []);

    return (
        <React.Fragment>
            <div className="design-container__clz">
                <div
                    className="d-flex flex-column"
                    style={{
                        height: "690px",
                    }}
                >
                    <div
                        className="cursor__clz pl-3 py-2"
                        style={{ font: "normal normal 600 22px/26px Segoe UI" }}
                    >
                        {t("Step: Order Taking")}
                    </div>
                    <div
                        className="d-flex flex-rows align-items-center"
                        style={{ gap: "10px" }}
                    >
                        <div
                            className="cursor__clz pl-3"
                            style={{
                                font: "normal normal 600 22px/26px Segoe UI",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {t("Sub-step")}:
                        </div>

                        <Nav
                            tabs={true}
                            style={{
                                display: "flex",
                                gap: "20px",
                            }}
                            className="d-flex align-items-center border-none disable-nav-tabs-border-bottom"
                        >
                            {tabs.map((element, index) => {
                                return (
                                    <NavItem
                                        key={index}
                                        className={classNames(
                                            "width__clz rounded w-auto",
                                            {
                                                "slide-color__clz":
                                                    activeTab === index,
                                                "disabled-sub-step-slide__clz":
                                                    activeTab !== index,
                                            }
                                        )}
                                        style={{
                                            height: "34px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <NavLink
                                            className={classNames("px-3 py-1", {
                                                "text-white":
                                                    activeTab === index,
                                                "text-dark":
                                                    activeTab !== index,
                                            })}
                                            style={{
                                                color:
                                                    activeTab !== index
                                                        ? "black"
                                                        : "white",
                                            }}
                                            onClick={() => {
                                                toggleNav(index);
                                                setSelectSubStepOrderTaking(
                                                    element.title
                                                );
                                            }}
                                        >
                                            <span>{element.title}</span>
                                        </NavLink>
                                    </NavItem>
                                );
                            })}
                        </Nav>
                    </div>
                    <TabContent activeTab={activeTab} className="h-100">
                        {tabs.map((element, index) => {
                            return (
                                <TabPane
                                    tabId={index}
                                    key={index}
                                    style={{ height: "90%" }}
                                >
                                    {element.content}
                                </TabPane>
                            );
                        })}
                    </TabContent>
                </div>
            </div>
        </React.Fragment>
    );
}
