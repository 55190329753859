import React from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { useSnapshot } from "valtio";

import { generalConfigStore, setIsActiveNoThanksButton } from "@store";

export function ActionsButtons(): JSX.Element {
    const { t } = useTranslation();

    const { project } = useSnapshot(generalConfigStore);

    const noThanksButton = (((project.template.pages.subSteps[
        "customerBenefit"
    ] as unknown) as CustomerBenefitPageType)
        .options as CustomerBenefitOptionsType).nothanksButton;

    function handleActiveSwitchNoThanksButton(
        setState: Function,
        state: boolean
    ) {
        setState(!state);
    }

    return (
        <div>
            <div
                className="mt-4 rounded border"
                style={{
                    width: "93%",
                    height: "96%",
                    borderColor: "#CECECE",
                }}
            >
                <div
                    className="text-left px-4 d-flex align-items-center cursor__clz"
                    style={{
                        backgroundColor: "#EDEDED",
                        height: "40px",
                        fontSize: "20px",
                    }}
                >
                    {t("Actions Buttons")}
                </div>
                <div className="p-4">
                    <div
                        className="py-2 d-flex flex-row justify-content-between align-items-center cursor__clz"
                        key={"index"}
                    >
                        <span>{t(`No thanks`)}</span>
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            onChange={() => {
                                handleActiveSwitchNoThanksButton(
                                    setIsActiveNoThanksButton,
                                    noThanksButton
                                );
                            }}
                            checked={noThanksButton}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
