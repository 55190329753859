import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

import React from "react";

import {
    AuthenticateButtonContainer,
    BadgeButton,
    BadgeContainer,
    GreetingSectionContainer,
    HeaderContainer,
    LoyaltyBalanceContainer,
} from "./styledCss";
import { t } from "i18next";

type loyaltyBtnType = {
    id: string;
    name: string;
    active: boolean;
    resetContent: string;
    languages: RefactoredLanguageType;
};

type loyaltyHeader = {
    logoutBtn: loyaltyBtnType;
    customerBenefitHeader: CustomerBenefitHeaderType;
    customerLanguage: string;
};

export default function Header({
    logoutBtn,
    customerBenefitHeader,
    customerLanguage,
}: loyaltyHeader): JSX.Element {
    const greetingMsg: string =
        customerBenefitHeader.informationMessage.languages[customerLanguage] ??
        customerBenefitHeader.informationMessage.resetContent;

    const customerBalanceMsg: string =
        customerBenefitHeader.customerBalance.languages[customerLanguage] ??
        customerBenefitHeader.customerBalance.resetContent;

    return (
        <HeaderContainer>
            {customerBenefitHeader.informationMessage.active ? (
                <GreetingSection greetingMsg={greetingMsg} />
            ) : null}
            {logoutBtn.active ? (
                <AuthenticateButton
                    logoutBtnMsg={logoutBtn}
                    customerLanguage={customerLanguage}
                />
            ) : null}
            {customerBenefitHeader.customerBalance.active ? (
                <LoyaltyBalance customerBalanceMsg={customerBalanceMsg} />
            ) : null}
            {customerBenefitHeader.customerAccountAdvantages ? (
                <HeaderBadges />
            ) : null}
        </HeaderContainer>
    );
}

type greetingSectionType = {
    greetingMsg: string;
};

function GreetingSection({ greetingMsg }: greetingSectionType): JSX.Element {
    return (
        <GreetingSectionContainer>
            {greetingMsg} Juliette 👋
        </GreetingSectionContainer>
    );
}

type authenticateButtonType = {
    logoutBtnMsg: any;
    customerLanguage: string;
};

function AuthenticateButton({
    logoutBtnMsg,
    customerLanguage,
}: authenticateButtonType): JSX.Element {
    const regex = /(logout|logout\.png|Déconnecter)/;
    return (
        <>
            {regex.test(logoutBtnMsg.languages[customerLanguage]) ? (
                <AuthenticateButtonContainer>
                    {t(`${logoutBtnMsg.name}`, {
                        lng: customerLanguage,
                    })}
                    <PowerSettingsNewIcon fontSize="small" />
                </AuthenticateButtonContainer>
            ) : (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "end",
                        gridRow: 1,
                        gridColumn: 4,
                        marginTop: " 0.5rem",
                    }}
                >
                    <img
                        src={logoutBtnMsg.languages[customerLanguage]}
                        alt="logout"
                        height={30}
                        width={80}
                    />{" "}
                </div>
            )}
        </>
    );
}

type loyaltyBalanceType = {
    customerBalanceMsg: string;
};

function LoyaltyBalance({
    customerBalanceMsg,
}: loyaltyBalanceType): JSX.Element {
    return (
        <LoyaltyBalanceContainer>
            <span>
                {customerBalanceMsg} {"(X)"} {t("points")}
            </span>
        </LoyaltyBalanceContainer>
    );
}

function HeaderBadges(): JSX.Element {
    return (
        <BadgeContainer>
            <BadgeButton>Profile Gold</BadgeButton>
            <BadgeButton>Vous avez 15% de rédection</BadgeButton>
            <BadgeButton>Vous avez 10% de rédection</BadgeButton>
            <BadgeButton>Vous avez 5% de rédection</BadgeButton>
        </BadgeContainer>
    );
}
