import React from "react";
import { TabContent, TabPane } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import {
    generalConfigStore,
    designStore,
    setInformationPrinterOptionsConfirmationEmailItems,
    setInformationPrinterOptionsEntryEmailItems,
} from "@store";

import "@components/Common/Design/index.css";
import "@assets/css/global.css";
import { MainContentTranslationEntryEmail } from "./MainContentTranslationEntryEmail";
import { MainContentTranslationVerificationEmail } from "./MainContentTranslationVerificationEmail";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";
import { VERIFICATION_EMAIL_PRINTER_OPTIONS_TAB } from "@constants/index";

export function Translation(): JSX.Element {
    const { t } = useTranslation();

    const { project } = useSnapshot(generalConfigStore);

    const { activeTabPrinterOptionsConfirmationEmail } = useSnapshot(
        designStore
    );

    const informationPrinterOptionsEntryEmailItems = ((project.template.pages
        .subSteps[
        "printerOptionsConfirmationEmail"
    ] as unknown) as PrinterOptionsConfirmationEmailSubStepType).header
        .informationMessage.entryemail;

    const informationPrinterOptionsConfirmationEmailItems = ((project.template
        .pages.subSteps[
        "printerOptionsConfirmationEmail"
    ] as unknown) as PrinterOptionsConfirmationEmailSubStepType).header
        .informationMessage.confirmationemail;

    const tabs: TabsType[] = [
        {
            title: t("Email verification"),
            content: <MainContentTranslationVerificationEmail />,
        },
        {
            title: t("Email entry"),
            content: <MainContentTranslationEntryEmail />,
        },
    ];

    function handleResetLanguagesTranslationsOnClickEvent() {
        if (
            activeTabPrinterOptionsConfirmationEmail ===
            VERIFICATION_EMAIL_PRINTER_OPTIONS_TAB
        ) {
            const localInformationMessageItems = Object.fromEntries(
                Object.entries(
                    informationPrinterOptionsConfirmationEmailItems.languages
                ).map(([key, value]) => [
                    key,
                    {
                        ...value,
                        content: value.defaultContent as string,
                    },
                ])
            );
            setInformationPrinterOptionsConfirmationEmailItems({
                ...informationPrinterOptionsConfirmationEmailItems,
                languages: localInformationMessageItems,
            });
        } else {
            const localInformationMessageItems = Object.fromEntries(
                Object.entries(
                    informationPrinterOptionsEntryEmailItems.languages
                ).map(([key, value]) => [
                    key,
                    {
                        ...value,
                        content: value.defaultContent as string,
                    },
                ])
            );
            setInformationPrinterOptionsEntryEmailItems({
                ...informationPrinterOptionsEntryEmailItems,
                languages: localInformationMessageItems,
            });
        }
    }

    return (
        <React.Fragment>
            
            <div
                className=" rounded border"
                style={{
                    width: "93%",
                    height: "200px",
                    borderColor: "#CECECE",
                    marginTop: "10px",
                }}
            >
            <div
                className="d-flex justify-content-between align-items-center"
                style={{
                    backgroundColor: "#EDEDED",
                    height: "40px",
                    fontSize: "20px",
                }}
            >
                <div className="text-left px-4 d-flex align-items-center">
                    {t("Translation")}
                </div>
                <StyledIconButton
                    title={t("Reset")}
                    className="m-0"
                    icon="RefreshIcon"
                    onClick={() => {
                        handleResetLanguagesTranslationsOnClickEvent();
                    }}
                >
                    <RefreshIcon height={15} width={15} />
                </StyledIconButton>
            </div>

            {activeTabPrinterOptionsConfirmationEmail ===
            VERIFICATION_EMAIL_PRINTER_OPTIONS_TAB ? (
                informationPrinterOptionsConfirmationEmailItems.active ? (
                    <TabContent
                        activeTab={activeTabPrinterOptionsConfirmationEmail}
                    >
                        {tabs.map((element, index) => {
                            return (
                                <TabPane
                                    tabId={index}
                                    key={index}
                                    style={{ height: "100%" }}
                                >
                                    {element.content}
                                </TabPane>
                            );
                        })}
                    </TabContent>
                ) : null
            ) : informationPrinterOptionsEntryEmailItems.active ? (
                <TabContent
                    activeTab={activeTabPrinterOptionsConfirmationEmail}
                >
                    {tabs.map((element, index) => {
                        return (
                            <TabPane
                                tabId={index}
                                key={index}
                                style={{ height: "100%" }}
                            >
                                {element.content}
                            </TabPane>
                        );
                    })}
                </TabContent>
            ) : null}
            </div>
        </React.Fragment>
    );
}
