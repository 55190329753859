import React from "react";
import { useSnapshot } from "valtio";

import {
    designStore,
    generalConfigStore,
    setActiveMainContentItems,
    setRegisterForm,
} from "@store";

import { TranslationWrapper } from "@components/Common/Design/MainContentWrapper/TranslationWrapper";

import "@components/Common/Design/index.css";
import "simplebar-react/dist/simplebar.min.css";
import { MainContentWrapper } from "./MainContentWrapper";
import { SubStepTranslationTableWrapper } from "@components/Common/Design/SubStepContent/SubStepTranslationTableWrapper";

export function Modes(): JSX.Element {
    const {
        mainContentItems,
        isSubStepActivated,
        isSubStepInformationMessageActive,
    } = useSnapshot(designStore);
    const { project } = useSnapshot(generalConfigStore);

    React.useEffect(() => {
        setActiveMainContentItems(
            mainContentItems.filter((item) => {
                return item.active === true;
            })
        );
        setRegisterForm(
            (project.template.pages.subSteps["accountFidelite"] as PageType)
                .registerForm as registerFormType
        );
    }, [mainContentItems, project.template.pages.subSteps]);

    return (
        <React.Fragment>
            <MainContentWrapper />
            {isSubStepActivated === false ? (
                <TranslationWrapper />
            ) : isSubStepInformationMessageActive === true ? (
                <SubStepTranslationTableWrapper />
            ) : null}
        </React.Fragment>
    );
}
