import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import React from "react";
import { Button, Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";

import { AvailableBalance, CardButton, GitsTitle } from "./styledCss";

import "swiper/swiper-bundle.min.css";

type loyaltyBtnType = {
    id: string;
    name: string;
    active: boolean;
    resetContent: string;
    languages: RefactoredLanguageType;
};

type giftSectionType = {
    gitsTitleMsg: string;
    useBtn: loyaltyBtnType;
};

export default function GiftSection({
    gitsTitleMsg,
    useBtn,
}: giftSectionType): JSX.Element {
    return (
        <div className="w-100" style={{ height: "8rem" }}>
            <GiftSectionTitle gitsTitleMsg={gitsTitleMsg} />
            <GiftsCardContainer useBtn={useBtn} />
        </div>
    );
}

type giftSectionTitleType = {
    gitsTitleMsg: string;
};

function GiftSectionTitle({ gitsTitleMsg }: giftSectionTitleType) {
    return (
        <GitsTitle>
            <strong>{gitsTitleMsg} </strong>
            <AvailableBalance>(4 available)</AvailableBalance>
        </GitsTitle>
    );
}
const gifts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

type GiftsCardContainerType = {
    useBtn: loyaltyBtnType;
};

function GiftsCardContainer({ useBtn }: GiftsCardContainerType) {
    return (
        <Swiper
            slidesPerView={4}
            slidesPerGroup={4}
            spaceBetween={10}
            pagination={{
                clickable: true,
            }}
            modules={[Navigation]}
            className="position-absolute w-100"
            style={{ maxWidth: "25rem", marginLeft: "1rem" }}
        >
            {gifts.map((_, index) => {
                return (
                    <SwiperSlide key={index}>
                        <GiftsCard key={index} useBtn={useBtn} />
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
}

type GiftsCardType = {
    useBtn: loyaltyBtnType;
};

function GiftsCard({ useBtn }: GiftsCardType) {
    return (
        <Card className="w-100">
            <CardBody
                className="d-flex flex-column align-items-start justify-content-between"
                style={{ height: "6rem", padding: "0.5rem" }}
            >
                <CardTitle
                    tag="h5"
                    style={{ fontSize: "8px", fontWeight: 900 }}
                >
                    MON ANNIVARSAIRE
                </CardTitle>
                <CardSubtitle
                    className="mb-2 text-muted"
                    tag="h6"
                    style={{ fontSize: "10px" }}
                >
                    {`Valable jusqu'a 20/02/20224`}
                </CardSubtitle>

                <Button className="align-self-center" style={CardButton}>
                    {useBtn.name}
                </Button>
            </CardBody>
        </Card>
    );
}
