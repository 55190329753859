import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { uid } from "react-uid";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";

import {
    generalConfigStore,
    setFiles,
    setImageKey,
    setProjectFiles,
} from "@store";

import { CustomErrorBoundary } from "@components/CustomErrorBoundary";
import { ResetModalWrapper } from "@components/Common/ResetModalWrapper";

import { ActionButtonItemWrapper } from "./ActionButtonItemWrapper";
import { LanguagesSelectWrapper } from "./LanguagesSelectWrapper";

export function DesignButtonsTabs(): JSX.Element {
    const { t } = useTranslation();

    const {
        project: { template, files: images },
        selectedActionsButtonsLanguage,
        isResetModalOpened,
    } = useSnapshot(generalConfigStore);

    const [designButtonsItems, setDesignButtonsItems] = React.useState<
        ActionsButtonsDesignItemType[]
    >((template.content.actionsButtons as ActionsButtonsDesignType).items);

    const [
        localIsResetModalOpened,
        setLocalIsResetModalOpened,
    ] = React.useState<boolean>(isResetModalOpened);

    function handleResetOnClickEvent() {
        const imagesArray = [...images];
        designButtonsItems
            .map((item: ActionsButtonsDesignItemType) => item.id)
            .forEach((id: string) => {
                const image = imagesArray.find((element) => element.id === id);
                if (image !== undefined) {
                    const imageIndex = imagesArray.indexOf(image);

                    const localContent = image.content as ImageItemLanguagesType;
                    if (image.id !== "08b4c907-8f40-41f9-ba6f-d75a493ed142") {
                        imagesArray[imageIndex] = {
                            ...image,
                            content: {
                                ...localContent,
                                [selectedActionsButtonsLanguage]: {
                                    ...localContent[
                                        selectedActionsButtonsLanguage
                                    ],
                                    path:
                                        localContent[
                                            selectedActionsButtonsLanguage
                                        ].defaultImage,
                                },
                            } as ImageItemLanguagesType,
                        };
                    } else {
                        imagesArray[imageIndex] = {
                            ...image,
                            content: image.defaultContent as string,
                        };
                    }
                }
            });

        setFiles(imagesArray);
        setProjectFiles(imagesArray);
        setImageKey(uid(imagesArray));
    }

    return (
        <CustomErrorBoundary>
            <div
                className="d-flex flex-column cursor__clz"
                style={{ gap: "20px" }}
            >
                <div
                    className="d-flex flex-column mt-3"
                    style={{ gap: "10px" }}
                >
                    <div
                        style={{
                            font: "normal normal 600 35px/61px Nunito Sans",
                        }}
                    >
                        {t("Action buttons")}
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                        <LanguagesSelectWrapper />
                        <StyledIconButton
                            title={t("Reset to default image")}
                            outline={true}
                            variant="danger"
                            className="m-0 p-0"
                            icon="RefreshIcon"
                            style={{ height: "20%", width: "20px" }}
                            onClick={() => {
                                setLocalIsResetModalOpened(
                                    !localIsResetModalOpened
                                );
                            }}
                        >
                            <RefreshIcon height={20} width={20} />
                        </StyledIconButton>
                    </div>
                </div>

                <div
                    className="d-grid__clz"
                    style={{
                        gap: "20px",
                    }}
                >
                    <div
                        className="d-flex justify-content-center"
                        style={{ gap: "80px" }}
                    >
                        {designButtonsItems
                            .slice(0, 4)
                            .map(
                                (
                                    item: ActionsButtonsDesignItemType,
                                    index: number
                                ) => {
                                    return (
                                        <ActionButtonItemWrapper
                                            item={item}
                                            index={index}
                                            key={index}
                                            setItems={setDesignButtonsItems}
                                        />
                                    );
                                }
                            )}
                    </div>
                    <div
                        className="d-flex justify-content-center"
                        style={{ gap: "80px" }}
                    >
                        {designButtonsItems
                            .slice(4)
                            .map(
                                (
                                    item: ActionsButtonsDesignItemType,
                                    index: number
                                ) => {
                                    return (
                                        <ActionButtonItemWrapper
                                            item={item}
                                            index={index}
                                            key={index}
                                            setItems={setDesignButtonsItems}
                                        />
                                    );
                                }
                            )}
                    </div>
                </div>
            </div>
            <ResetModalWrapper
                handleResetOnClickEvent={handleResetOnClickEvent}
                localIsResetModalOpened={localIsResetModalOpened}
                setLocalIsResetModalOpened={setLocalIsResetModalOpened}
            />
        </CustomErrorBoundary>
    );
}
